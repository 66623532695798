.application-footer {
    width: 100%;
    padding: var(--grid-2x) var(--grid-14x);
    box-sizing: border-box;
    color: var(--white);
    background-color: var(--onyx);
}

html[data-brand=vwq] {
    .application-footer {
        padding: var(--grid-2x) var(--grid-4x);
    }

    .application-footer .Footer_module_footerBottom__d5e8ed8d {
        justify-content: left;
        padding:0;
        padding-top: 12px;
        padding-bottom: 12px;
    }
    .application-footer .Footer_module_footerCopyright__d5e8ed8d  p {
        padding-top: 24px;
        border-top: solid 2px var(--white) !important;
        text-align: left !important;
    }
    .application-footer .Grid_module_grid__da3b8c09  {
        margin-bottom: 24px;
    }
    .application-footer .Grid_module_md3Lg6__3b65f3ed .Grid_module_grid__item__3b65f3ed  {
        width: 24.99%;
    }

    .logo-blur {
        max-width: 400px;
        line-height: 18px;
        margin-top: 6px;
    }

    .Footer_module_footerSection__d5e8ed8d ul li { 
        margin-top: 1rem !important;
    }

    .Footer_module_footerSection__d5e8ed8d ul li a span.vwq-icon { 
        margin-left: .5rem !important;
    }
}
/* Tablets */
@media screen and (max-width: 769px) {
	.application-footer {
        padding: var(--grid-2x) var(--grid-3x);
    }
}

@media screen and (max-width: 1024px) {	
	.application-footer {
        padding: var(--grid-2x) var(--grid-3x);
    }
}
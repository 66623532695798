/* to-do: need to verify the contract part as there are some class commented out*/
*:before, *:after {
	box-sizing: inherit;
}
.contract-heading {
    margin-top: 24px;
 	margin-bottom: 24px;
}

.quote-details-container {
	width: 100%;
	box-sizing: border-box;
}

.group-contract-container, .group-contract-header, .group-contract-row, .html-row {
	width: 100%;
	box-sizing: border-box; 
}

.html-row { 
    table > tr > td > table > tr > td > b {
        width: 100%;
        box-sizing: border-box;
    }
}

.group-contract-contents, .group-contract-footer {
	width: 100%;
	padding: 8px 80px;
    box-sizing: border-box;
	overflow:auto;
}

.group-contract-group-information-header, .group-contract-quote-number-header {
	width: 100%;
	padding: 16px !important;
	font-weight: 700;
	background: var(--group-contract-header-bg);
	color: var(--white);
	font-size: 18px;
	box-sizing: border-box;
    border-radius: 4px;
	overflow:auto;
}
.group-contract-quote-number-header svg {
	width: 20px;
	height: 24px;
	margin: 0 8px 0 0;
}
.group-contract-quote-number-header::after {
	content: "";
	display: table;
	clear: both;
}
.group-contract-group-terms-and-conditions-header {
	width: 100%;
	padding: 8px 16px;
	font-weight: 700;
	background: var(--group-contract-section-header-bg);
	color: var(--white);
	font-size: 18px;
	box-sizing: border-box;
    border-radius: 4px;
	overflow:auto;
}

.group-contract-group-terms-and-conditions-header > div:first-child {
	margin-top: 6px;
	margin-bottom: 8px;
	display:inline-block;
}
.group-contract-row {
	display: block !important;
	overflow: auto;
}

.clear-fix {
  content: "";
  display: table;
  clear: both;
}

.group-contract-footer-row {
	width: 100%;
	padding: 40px 0;
}
.signature-footer {
	font-size: 14px;
}
.group-contract-terms-and-conditions {
	font-size: 14px;
}
.group-contract-created-date-header {
	width: 100%;
	padding: 16px;
	background: var(--onyx-bg);
	text-align: right;
    box-sizing: border-box;
    border-radius: 4px;
}

.col-left {
	width: 50%;
	float: left;
}

// .margin-top-2x {
// 	margin-top: 16px;
// }
// .margin-top-4x {
// 	margin-top: 32px;
// }
// .margin-top-6x {
// 	margin-top: 48px;
// }
.margin-top-10x {
	margin-top: 80px;
}
// .margin-bottom-1x {
// 	margin-bottom: 8px;
// }
// .margin-bottom-2x {
// 	margin-bottom: 16px;
// }
// .border-top {
// 	border-top: 1px solid var(--onyx-brdr);
// }


.html-row {
	> table > tr > td > table > tr > td {
		padding: 4px 16px 4px 16px;
		> b {
			padding-top: 12px;
		}
		&[colspan="11"] {
			padding: 16px 16px 16px;
			background: var(--onyx-bg);
            border-radius: 4px;
			> b {
				font-weight: 400;
			}
		}
	}
	> table > tr > td > table > tr:first-child > td {
		background: var(--group-contract-html-row-bg);
		color: var(--white);
		font-size: 18px;
		text-decoration: none;
		border-radius: 4px;
		font-weight: 700;
		padding: 16px;
	}
}

.html-row u {
	text-decoration: none;
}

.cancel-button {
    height: 38px;
    background-color: var(--white) !important;
    color: var(--error-fg) !important;
    border: 1px solid var(--white) !important;
  }

  .group-contract-button {
      width: 100%;
      text-align: center;
  }
  
.break-word{
	word-wrap: break-word;
}

.signature-box{
	font-size: 80px;
    margin: 80px 0;
	color: var(--white);
}

.date-box{
	font-size: 24px;
	margin: 8px;
	display: inline;
	font-weight: 600;
	color: var(--white);
	height: 24px;
}

.col-left-25-percent {
	width: 25%;
	float: left;
  }
  .col-left-50-percent {
	width: 49.9%;
	float: left;
  }

  .add-initials {
	font-size: 80px;
	margin: 80px 0;
  	color: var(--white);
  }

  .footer-statement {
	margin: 30px 0;
    text-align: center;
    color: var(--iridium);
  }
  
  .company-heading {
	width: 100%;
	background: var(--company-heading-bg);
	padding: 20px 80px 14px;
	border-bottom-right-radius: 30px;
	box-sizing: border-box;
	text-align: left;
  }
  .company-heading svg {
	height: 50px !important;
  }

  .group-contract-row {
	padding: 2px 16px;
  }

  .group-contract-row p {
	margin-top:0px;
  }

  .group-contract-header, .group-contract-contact-info {
	overflow: auto;
  }
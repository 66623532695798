.view-quote-product-type-itinerary-row  {
    width: 100%;
    float: left;
    border-radius: 4px;
    padding: var(--grid-1x);
    box-sizing: border-box;
    background: var(--onyx-bg);

    .itinerary-short-info {
        color: var(--quote-view-itinerary-short-info);
    }
}

.view-quote-product-type-itinerary-row:hover, .isActive-row, .active-row {
    cursor: pointer;
    color: var(--quote-view-itinerary-row-heading-fg);
    background: var(--quote-view-itinerary-row-heading-bg);

    .itinerary-short-info {
        color: var(--white);
    }
}

.view-quote-product-type-itinerary-row .font-white {
    font-size: 20px;
}

.view-quote-product-type-itinerary-row h3 {
    width: auto;
    float: left;
    font-size: 20px;
}

.itinerary-header {
    width: auto;
    float: left;
    font-size: 20px;
    font-weight: var(--quote-view-itinerary-header-weight);
}

.view-quote-product-type-itinerary-details {
    width: 100%;
    float: left;
    padding: var(--grid-1x);
    box-sizing: border-box;
}

.view-quote-product-type-itinerary-details-col-1 {
    width: 240px;
    float: left;
}

.view-quote-product-type-itinerary-details-col-2 {
    width: 30%;
    float: left;
    padding: 0 var(--grid-1x);
    box-sizing: border-box;
}

.view-quote-product-type-itinerary-details-col-3 {
    width: calc(70% - 240px);
    float: left;
    padding: 0 var(--grid-1x);
    box-sizing: border-box;
}

html[data-brand=vwq] {
    .view-quote-product-type-itinerary-details-col-1 {
        width: 260px;
    }
    
    .view-quote-product-type-itinerary-details-col-3 {
        width: calc(70% - 260px);
    }
}

.view-quote-product-type-itinerary-details-guest-col {
    width: calc(100% - 240px);
    float: left;
    padding: 0 var(--grid-1x);
    box-sizing: border-box;
}
.view-quote-product-type-itinerary-details-deviation-col {
    width: calc(100% - 480px);
    float: left;
    padding: 0 var(--grid-1x);
    box-sizing: border-box;
}
.view-quote-product-type-itinerary-details-guest-col .col-3 {
    min-height: inherit!important;
    padding: 0!important;
}

.view-quote-product-type-itinerary-details-guest-col-1 {
    width: 50%;
    float: left;
    padding: 0 var(--grid-1x);
    box-sizing: border-box;
}

.view-quote-product-type-itinerary-details-guest-col-2 {
    width: 50%;
    float: left;
    font-size: 14px;
}

.offer-row {
    border: 1px solid var(--offer-row-fg)!important;
    color: var(--offer-row-fg)!important;
    margin-top: var(--grid-1x)!important;
}

.itinerary-promotion-row {
    border: 1px solid var(--itinerary-promotion-row-fg) !important;
    color: var(--itinerary-promotion-row-fg) !important;
    margin-top: var(--grid)!important;
    margin-bottom: var(--grid-1x)!important;
}

.itinerary-promotion-row, .offer-row {
    width: 100%;
    float: left;
    border-radius: 4px;
    text-align: left!important;
    padding: var(--grid) !important;
    justify-content: flex-start!important;
}

.itinerary-limitedInventoryText {
    color: var(--error-fg) !important;
}

.promotions-icon {
    width: var(--grid-2x);
    height: var(--grid-2x);
    text-align: center;
    
}

@media (min-width: 300px) and (max-width: 767px) {
    .early-pricing-row {
        width: 30% !important;
    }
    .view-quote-product-type-itinerary-details-col-1 .row, .view-quote-product-type-itinerary-details-col-2 .row, .view-quote-product-type-itinerary-details-col-3 .row {
    margin-top: 0;
    }
    .responsive .view-quote-product-type-itinerary-details-guest-col .col-3:nth-child(1) {
        width: calc(30% - var(--grid-1x)) !important;
    }
    .person-price-details {
        width: 100%;
        display: flex;
    }
    .early-booking-row.col-3 {
        width: 70% !important;
    }
    .responsive .view-quote-product-type-itinerary-details-guest-col .col-3 {
        width: 70% !important;
        margin-right: 0;
    }
  .price-container .row .col-2 {
    width: 80%;
}
}
.concession-list > div {margin-bottom: 10px;}
.concession-list > div:last-child {margin-bottom: 0;}    


.itinerary-validity {
    display: inline;
}

.font-warning {
    color: var(--error-fg)
}
.view-quote-product-type-itinerary-row:hover .font-warning, .active-row .font-warning  {
    color: var(--warning-fg)
}

@media (min-width: 300px) and (max-width: 767px) {
    .itinerary-validity {
        display: block;
    }
    .itinerary-validity .itinerary-validity-vertical-bar {
        display: none;
    }   
}
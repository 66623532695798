.progress-indicator-container {
    width: 100%;
    height: var(--grid);
    float: left;
    background: var(--onyx-brdr-opacity-30);

    -webkit-border-top-left-radius: calc(var(--grid) / 2);
    -webkit-border-top-right-radius: calc(var(--grid) / 2);
    -moz-border-radius-topleft: calc(var(--grid) / 2);
    -moz-border-radius-topright: calc(var(--grid) / 2);
    border-top-left-radius: calc(var(--grid) / 2);
    border-top-right-radius: calc(var(--grid) / 2);
}

.progress-indicator {
    height: var(--grid);
    float: left;
    background-color: var(--progress-indicator-bg);

    -webkit-border-top-left-radius: calc(var(--grid) / 2);
    -webkit-border-top-right-radius: calc(var(--grid) / 2);
    -moz-border-radius-topleft: calc(var(--grid) / 2);
    -moz-border-radius-topright: calc(var(--grid) / 2);
    border-top-left-radius: calc(var(--grid) / 2);
    border-top-right-radius: calc(var(--grid) / 2);
}

.progress-indicator-step-1 {
    width: 25%;
}

.progress-indicator-step-2 {
    width: 50%;
}

.progress-indicator-step-3 {
    width: 75%;
}
.itinerary-details {
    width: calc(70% - 240px);
    float: left;
    font-size: 14px;
}

html[data-brand=vwq]{
    .itinerary-details {
        width: calc(67% - 240px);
    }
}

.itinerary-details .link-button {
    width: 100%;
    float: left;
    height: auto!important;
    padding: 2px 0!important;
    margin-top: 0!important;
}

.validity-row {
    width: 100%;
    float: left;
    height: auto!important;
    padding: 2px 0!important;
    margin-top: 0!important; 
}

.validity-row .font-error {
    margin-top: 4px;
}

.flight-icon {
    margin-top: -4px;
}

.option-icon {
    width: var(--grid-3x);
    float: left;
    text-align: center;
}

.option-text {
    width: calc(100% - var(--grid-3x));
    float: left;
}

.itinerary-status {
    width: auto;
    float: left;
    font-size: 12px;
    padding: 0 8px;
    border-radius: 4px;
}

.pending {
    background: var(--error-fg);
    color: var(--white);
    border: 1px solid var(--error-fg);
}

.confirmed {
    background: var(--itinerary-status-confirmed-bg); 
    color: var(--white);
    border: 1px solid var(--white);
}

.unavailable, .adjusted, .manual, .inactive {
    background: var(--white);
    border: 1px solid var(--itinerary-status-unconfirmed-brbr);
}

.itinerary-col {
    width: calc(65% - 16px);
    float: left;
    margin-right: 16px;
}

.itinerary-validity-col {
    width: 35%;
    float: left;
}

.itinerary-heading {
    width: calc(100% - 146px);
    float: left;
}
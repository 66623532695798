.modal {
  :global {
    .popup-container {
      .icon {
        color: var(--onyx3);
        position: absolute;
        top: 24px;
        right: 24px;
        cursor: pointer;
      }
    }
  }
}

@media (min-width: 1025px) and (max-width: 1365px) {
    .app-container {
        padding: var(--grid-4x) var(--grid) !important;
    }

    .application-footer {
        padding: var(--grid-4x) var(--grid) !important;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .responsive-quote-options {
        display: none;
    }

    header ul li a.active::after {
        bottom: -18px !important;
    }

    .row-additional-information {
        padding: var(--grid-1x) var(--grid-1x) !important;
    }

    .responsive .row-additional-information h3 {
        font-weight: 700 !important;
    }

    .product-types-container {
        display: none !important;
    }

    .margin-reset {
        margin: 0 !important;
    }

    .responsive .information-text {
        text-align: left !important;
        padding: 0 var(--grid-1x) 0 0 !important;
    }

    .promotion-row,
    .pricing-notes-information-row,
    .terms-and-conditions-information-row {
        padding: var(--grid-2x) 0 !important;
    }

    .add-quote-modal.popup-container {
        width: calc(100% - 40px) !important;
    }

    .responsive .wizard-container .col-3:nth-child(3) {
        display: none;
    }

    .responsive .wizard-container .summary .col-3:nth-child(3) {
        display: block;
    }
    .responsive .wizard-container .travelers-count.col-3:nth-child(3) {
        display: block;
    }

    .responsive .wizard-footer .col-3,
    .responsive .wizard-footer .col-3-no-margin-r {
        width: calc(33.33% - var(--grid-1x)) !important;
    }

    .col-1-6,
    .col-1-8 {
        width: 31.1% !important;
        margin-bottom: var(--grid-1x);
    }

    .screen-quote-options,
    .showOrHide {
        display: block !important;
    }

    .grid-head .quote-number {
        width: 144px !important;
        margin-left: 40px;
        margin-right: var(--grid-1x);
    }

    .quote-options-container {
        width: calc(100% - 48px) !important;
        margin-left: 48px;
        box-sizing: border-box;
        padding: 0 0 var(--grid-1x) 8px !important;
    }

    .quote-number {
        width: 200px !important;
    }

    .quote-group-name {
        width: calc(100% - 374px) !important;
    }

    .quote-gateway,
    .quote-destination {
        width: calc(50% - 270px) !important;
        float: left;
    }

    .responsive .col-3,
    .responsive .col-3-no-margin-r {
        width: calc(50% - var(--grid-1x)) !important;
    }

    .price-details-container {
        .col-3.col-left-price-value{
            .price-val{
                height: 48px !important;
            }
            width: 30% !important;
        } 
        .col-6.col-left-price-details{
            width: 60% !important;
        } 
    }
    .slick-slide {
        width: 50% !important;
        height: inherit !important;
    }

    .slick-track
    {
        display: flex !important;
        flex-wrap: wrap;
    }

    .slick-slide:last-child {
        padding-top: var(--grid-2x);
        padding-right: var(--grid-1x) !important;
    }

    .responsive .col-3 {
        padding-bottom: var(--grid-1x);
    }

    .app-container {
        padding: var(--grid-4x) var(--grid) !important;
    }

    .review-group-responsive .page-main-container {
        padding: var(--grid-2x) var(--grid) 0 var(--grid);
    }

    .review-group-responsive .quote-information-row {
        padding: 0 var(--grid) var(--grid-1x) var(--grid);
    }

    .review-group-responsive .hotel-pic {
        margin-bottom: 20px;
    }

    .review-group-responsive .col-3 {
        width: 47%;
    }

    .review-group-responsive .hotel-details {
        width: 65%;
    }

    .review-group-responsive .itinerary-details {
        width: 100% !important;
    }

    .review-group-responsive .pricing-notes-information-row,
    .terms-and-conditions-information-row {
        padding: var(--grid-1x) var(--grid-1x) !important;
    }

    .review-group-responsive .outer-block {
        column-count: 1;
    }
}

@media (min-width: 1024px) and (max-width: 1200px) {
    .review-group-responsive .outer-block {
        column-count: 2 !important;
    }
}

@media (min-width: 300px) and (max-width: 767px) {
    .is-mobile-screen {
        display: block !important;
    }
    .review-group-responsive .outer-block {
        column-count: 1;
    }

    .row-additional-information {
        padding: 0!important;
        border-radius: 8px!important;
    }

    .background-blue {
        padding: 16px!important;
        border-radius: 8px!important;
    }
    header ul li a.active::after {
        bottom: -18px !important;
    }

    .wizard-container {
        padding-bottom: 0!important;
    }

    .MuiInputBase-root {
        height: auto!important;
    }

    .wizard-footer {
        padding-top: 0!important;
    }

    .padding-bottom-1x .MuiButton-root {
        padding: 0!important;  
        min-width: auto!important;
    }

    .padding-bottom-1x .option-heading {
        width: 100%!important;
    }

    .padding-bottom-1x strong {
        width: 100%!important;
        float: left!important;
        margin-bottom: 16px!important;
    }

    .slick-list .slick-slide .col-3 {
        padding-top: 0px!important;
        padding-bottom: 32px!important;
    }

    .slick-list .slick-slide {
        padding-right: 0!important;
        padding-bottom: 0!important;
    }

    .slick-slide .col-3 {
        padding-top: 0!important;
    }

    .margin-reset {
        margin: 0 !important;
    }

    .hide-add-ons {
        display: none !important;
    }

    .banner h1 {
        font-size: 28px !important;
    }

    .show-add-ons {
        display: block !important;
    }

    .pricing-notes-text, .terms-and-conditions-text, #noticeOfConfidentialityDisplayText {
        display: none!important;
    }

    .link-row .link-button {
        padding: 0 0 4px 0!important;
    }

    .group-information-container {
        margin-bottom: var(--grid-3x)!important;
    }

    .is-mobile {
        display: none!important;
    }
    .email-responsive {
        width: 100% !important;
        text-align: center !important;
        margin-top: 16px !important;
    } 
    .quote-options-container {
        width: 100% !important;
        float: right;
    }
    .quote-hotel-or-cruise-container{
        display: none;
    }
    .quote-number.Soumission {
        width: 100%;
    }
    .quote-group-name { 
        width: 100% !important;
    }

    .emailAddress-col .col-6.email-responsive{
        width: 100% !important;
    }
    .action-buttons-responsive {
        .MuiButton-root{
            height: auto !important;
            padding: 10px !important;
            width: 100%;
            margin-bottom: 10px;
        }
    }
    .is-desktop {
        display: block!important;
        height: auto !important;
       
        .quote-details-buttons {
            text-align: center !important;
            width: 100% !important;
            border-right: none !important;
            margin-right: 0 !important;
            
            
            .MuiButton-root{
                height: auto !important;
                padding: 10px !important;
                width: 100%;
                border-bottom: 1px solid var(--onyx-brdr) !important;
                border-radius: 0 !important ;
            }
        }
        
    }

    .promotion-tile {
        color: var(--onyx)!important;
    }

    .show-pricing-notes, .show-terms-and-conditions {
        display: block !important;
        width: 100%;
        float: left;
        text-align: center;
        padding-top: 8px!important;
    }

    .show-pricing-notes .MuiButton-root {
        width: 100%!important;
    }

    .product-types-container {
        display: none !important;
    }

    .show-add-ons .MuiButton-outlined {
        color: var(--white) !important;
        border: 1px solid var(--white) !important;
    }

    .banner .font-24 {
        font-size: 18px !important;
        line-height: 24px;
    }

    .responsive .information-text {
        width: 100% !important;
        text-align: left !important;
        padding: 0 0 var(--grid-1x) 0 !important;
    }

    .responsive .hotel-pic {
        width: 100% !important;
        margin-bottom: var(--grid-1x);
    }

    .responsive .view-quote-product-type-itinerary-details-guest-col {
        width: 100% !important;
        padding: 0 !important;
    }

    .responsive .view-quote-product-type-itinerary-details-guest-col .col-3:nth-child(1) {
        width: calc(20% - var(--grid-1x)) !important;
        padding: 0 !important;

        
    }
    .responsive .view-quote-product-type-itinerary-details-guest-col .price-details-container {
        .col-3.col-left-price-value{
            .price-val{
                height: 48px !important;
            }
            width: 30% !important;
        } 
        .col-6.col-left-price-details{
            width: 60% !important;
        } 
    }

    .responsive .view-quote-product-type-itinerary-details-guest-col .col-3 {
        width: calc(50% - var(--grid-1x)) !important;
        padding: 0 !important;
    }

    .responsive .row-additional-information h3 {
        font-weight: 700 !important;
    }

    .responsive .view-quote-product-type-itinerary-details-col-1,
    .responsive .view-quote-product-type-itinerary-details-col-2,
    .responsive .view-quote-product-type-itinerary-details-col-3 {
        width: 100% !important;
        padding: 0 !important;
    }

    .responsive .hotel-details {
        width: 100% !important;
        margin: var(--grid-1x) 0;
    }

    .promotion-row,
    .pricing-notes-information-row,
    .terms-and-conditions-information-row {
        padding: 0 !important;
    }

    .promotions-container, .button-center {
        display: none!important;
    }

    .responsive .MuiFormGroup-root .MuiFormControlLabel-root {
        width: 100%;
    }

    .box-container {
        width: 100%!important;
        float: left!important;
    }

    .popup-container {
        width: 90% !important;
    }

    .responsive .wizard-footer .col-3 {
        width: 33.33% !important;
        margin-right: 0!important;
        /* width: 100% !important; */
    }

    .responsive .wizard-footer .col-3-no-margin-r {
        width: calc(33.33%) !important;
        /* width: 100% !important; */
    }

    .pull-right {
        float: right!important;
    }

    .responsive .app-container {
        padding: var(--grid-2x) var(--grid);
    }

    .responsive .wizard-container .col-3:nth-child(3) {
        display: none;
    }
    .responsive .wizard-container .travelers-count.col-3:nth-child(3) {
        display: block;
    }

    .responsive .application-footer {
        padding: 0 var(--grid) var(--grid-2x) var(--grid);
    }

    .responsive .application-footer p {
        width: 100%;
        float: left;
        padding-top: var(--grid-1x);
    }

    .responsive .application-footer ul li {
        width: 50%;
        float: left;
    }

    .responsive .col-3,
    .responsive .slick-slider .slick-slide {
        width: 100% !important;
        float: left;
        padding-bottom: 0!important;
        /* padding-bottom: var(--grid-1x); */
    }

    .responsive .col-3-no-margin-r {
        width: 100% !important;
        float: left;
    }

    .responsive .heading {
        width: 40% !important;
    }

    .responsive .value {
        width: 60% !important;
    }

    .popup-container{
        overflow-x: scroll;
        height: auto;
        overflow-y: auto;
        max-height: calc(100vh - 64px);
        top: 5% !important;
    }

}

@media all and (orientation:landscape) {
    /* Style adjustments for landscape mode goes here */
    .popup-container{
        overflow-x: scroll;
        height: 100%;
        overflow-y: auto;
        max-height: calc(100vh - 50px);
    }
  }
  @media all and (orientation: landscape) {
    .popup-container {
        height: auto
    }
    }

@media (min-width: 300px) and (max-width: 480px){
    .responsive .view-quote-product-type-itinerary-details-guest-col .col-3.price-header {
        width: 28% !important;
    }
    .responsive .view-quote-product-type-itinerary-details-guest-col .price-details-container {
        
        .col-3.col-left-price-value{
            .price-val{
                height: 72px !important;
            }
            width: 28% !important;
        } 
        .col-6.col-left-price-details{
            width: 60% !important;
        } 
    }
}

.profile-information-container {
    width: 100%;
    float: left;
    margin-bottom: var(--grid-3x);
}

.profile-information-container .link-button {
    padding: 4px 0!important;
}

.profile-information-container .Localize-text {
    font-size: var(--grid-1x)!important;
    margin-bottom: 0!important;
}

@media (min-width: 300px) and (max-width: 767px) {
    .add-quote .heading {
        width: 60% !important;
    }
}

.departure-city-input {
    position: relative;
    z-index: 9999;
    width: 100%;
    padding: 8px;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    font-size: 14px !important;
}
.departure-city-input.menu-open{
    border-bottom: none;
    border-radius: 4px 4px 0  0;
}

.departure-city-input:active,
.departure-city-input:focus{
    border: 1px solid #c4c4c4;
    border-bottom: none;
    outline: none;
}
.departure-city-wrapper {
    position: absolute;
    height: 340px;
    max-width: 900px;
    min-width: 360px;
    .departure-city-popup {
      border: 1px solid #c4c4c4;
      position: relative;
      background-color: #fff;
      z-index: 999;
      bottom: 1px;
      box-shadow: 0.5px 0.5px 5px #c3c3c3;
      height: 340px;
      padding: 16px 0px;
      .select-info{
        font-size: 14px;
        padding-left: 8px;
        margin-bottom: 8px;
      }
        .list-container{
          padding: 16px 8px;
          max-height: 240px;
          overflow: scroll;
        }        
    }
    .bottom-section{
      position: absolute;
      bottom: 16px;
      right: 16px;
      z-index: 9999;
    }

}
.ft-right{
  float: right !important;
}
.countries-container{

  width: 100%;
  height: 100%;
  border-radius: 0 4px 4px 4px;
  -webkit-column-width: 150px;
  -moz-column-width: 150px;
  -o-column-width: 150px;
  -ms-column-width: 150px;
  column-width: 150px;
  .countries{
    display: inline-block;
    width: 160px;
    padding: 4px;
    margin-bottom: 8px;
    .MuiCheckbox-root{
      display: inline-block;
      // padding: 0 !important;
      height: 10px;
    }
    .country-label{
      display: inline;
      font-size: 14px;
    }
    .cities-container{
      margin-left: 0px;
      .cities{

        line-height: 20px;
        .MuiCheckbox-root{
          display: inline;
          padding: 0 !important;
          margin-right: 8px !important;
          line-height: 24px;
          background-color: #fff !important;
          .MuiSvgIcon-root{
            height: 16px;
            width: 16px;
            color: #8f8f8f;
          }
        }
        .MuiCheckbox-root.Mui-disabled{
          
          .MuiSvgIcon-root{
            height: 16px;
            width: 16px;
            color: #c9c9c9;
          }
        }
        .cities-label{
          display: inline;
          font-size: 14px;
        }
      }
    }
  }
}

.list-container::-webkit-scrollbar {
  width: 4px;
  height: 0px;
}
.list-container::-webkit-scrollbar-thumb {
  background-color: #7a7a7a;
}
.select-caret {
  float: right;
    position: relative;
    bottom: 32px;
    z-index: 9999;
    right: 8px;
    .MuiSvgIcon-root{
      color: #595959;
    }
}

.select-caret.invert {
    .MuiSvgIcon-root{
      transform: rotate(180deg);
    }
}
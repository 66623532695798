.information-text {
    width: calc(100% - 200px);
    float: left;
    text-align: right;
    padding: 0;
    box-sizing: border-box;
    line-height: 20px;
}

.information-button {
    width: 200px;
    float: left;
}

 .information-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    /* float: left; */
    padding: var(--grid-1x);
    border-radius: 4px;
  }
.view-group-itinerary-container {
    width: 100%;
    float: left;
}

.view-quote-itinerary-concession-col-min {
    width: 12.5%;
    float: left;
    display: table-cell;
    height: 100%;
}

.view-quote-itinerary-concession-col-medium {
    width: 25%;
    float: left;
    display: table-cell;
    height: 100%;
}


.view-quote-itinerary-updated-on {
    width: 180px;
    float: left;
    padding: 0 8px 0 16px;
    box-sizing: border-box;
}

.view-quote-itinerary-log {
    width: 720px;
    float: left;
    padding: 0 8px 0 16px;
    box-sizing: border-box;
}

.btn-green {
    background: var(--btn-green-bg) !important;
    height: 38px;
}

.table {
    width: calc(100% - 2px);
    display: table;
    height: 100%;
    border: 1px solid var(--onyx-brdr);
    border-radius: 4px;
}

.dark-bg {
    background: var(--onyx2) !important;
    color: var(--white);
}

.table-head {
    width: 100%;
    float: left;
    background: var(--onyx-bg);
    border-bottom: 2px solid var(--white);
    line-height: 18px;
    /* font-weight: 700; */
}

.table-body {
    width: 100%;
    float: left;
    background: var(--onyx-bg);
}

.table-row {
    width: 100%;
    height: 100%;
    float: left;
    display: table-row;
}

.itinerary-information-container .table-body .table-row:hover {
    background: var(--onyx-bg);
}

.table-body .table-row:hover {
    background: var(--white);
}

.table-head .table-column {
    padding: var(--grid-1x) var(--grid);
}

.table-column {
    vertical-align: top;
    display: table-cell;
    height: 100%;
    padding: var(--grid);
    box-sizing: border-box;
}

.table-column div {
    text-wrap:wrap;
}

.itinerary-information-container .table-column {
    padding: 2px var(--grid);
}

.itinerary-information-container .first-row .table-column-min:first-child {
    width: 12.5%;
}

.itinerary-information-container .first-row .table-column-min:nth-child(3) {
    width: 40%;
}

.itinerary-information-container .first-row .table-column-min:nth-child(4) {
    width: 12.5%;
}

.itinerary-information-container .first-row .table-column-min:last-child {
    width: 15%;
    float: right;
}

.table-column-min {
    width: 12.5%;
    display: table-cell;
    height: 100%;
}

.table-column-small {
    width: 10%;
    display: table-cell;
    height: 100%;
}

.table-head .table-column-large {
    float: left;
}

.itinerary-information-container {
    width: 100%;
    float: left;
    border: none;
}

.itinerary-information-container:first-child {
    border-bottom: 2px solid var(--white);
}

.itinerary-information-container .table-body {
    padding: var(--grid);
    box-sizing: border-box;
}

.itinerary-information-container .table-column-min {
    width: 20%;
    float: left;

}

.margin-override .table-column-large {
    width: 50%;
    display: table-cell;
    height: 100%;
}

.margin-override .table-column-min {
    width: 25%;
    display: table-cell;
    height: 100%;
}

.margin-override .table-row-h-text {
    width: 100px;
    float: left;
    padding: 8px 0;
    box-sizing: border-box;
}

.margin-override .table-row-h-input {
    width: calc(100% - 100px);
    float: left;
}

.table-column-large {
    width: 37.5%;
    display: table-cell;
    height: 100%;
}

.table-row-h-text {
    width: 40px;
    float: left;
    padding: 8px 0;
    box-sizing: border-box;
}

.table-row-h-input {
    width: calc(100% - 40px);
    float: left;
}

.view-group-itinerary-container Section {
    margin: 0;
    padding: 0;
}

.text-transparent {
    color: transparent;
}

.itinerary-notes {
    width: 100%;
    float: left;
    border: 1px solid var(--onyx-brdr);
    border-radius: 4px;
    padding: 0;
    box-sizing: border-box;
}

.itinerary-notes .MuiOutlinedInput-notchedOutline {
    border: 0 !important;
}

.itinerary-notes .MuiInputBase-root {
    height: inherit;
}

.table-pricing-col-text {
    width: 240px;
    float: left;
    display: table-cell;
    height: 100%;
}

.table-pricing-col-input {
    width: 180px;
    float: left;
    display: table-cell;
    height: 100%;
}

.freeze-column {
    width: 240px;
    float: left;
}

.scroll-container {
    /* overflow: auto;
    overflow-y: hidden;
    white-space: nowrap; */
    /* max-width: 100vw;
  overflow-x: scroll;
  position: relative;
  scrollbar-color: #d5ac68 #f1db9d;
  scrollbar-width: thin;
  -ms-overflow-style: none; */

    width: calc(100% - 240px);

    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;

    overflow: hidden;
}

.scroll-container:hover {
    overflow-x: scroll;
}

.scroll-columns {
    width: 3500px;
    float: left;
}

.pricing-details-container .table-head .table-column {
    padding: var(--grid-1x) var(--grid);
    height: 72px;
    word-wrap: break-word;
}

.pricing-details-container .table-body .table-column {
    height: 56px;
}

.container-border {
    width: 100%;
    float: left;
    border: 1px solid var(--onyx-brdr);
}

.container-bg {
    background: var(--white);
}

@media screen and (max-width: 769px) {

    .col-half.info-box {
        width: calc(100% - var(--grid-1x)) !important;
        margin: 4px 0;
    }

}

.deviation-col {
    width: 100%;
    float: left;
    margin-right: var(--grid-1x);
    padding-bottom: 4px;
}

.deviation-action-col {
    width: 40px;
    float: right;
}

.add-button {
    min-width: 100% !important;
    height: 40px;
    padding: var(--grid) !important;
}


.col-half {
    width: calc(48% - var(--grid-1x)) !important;
    float: left;
    margin-right: var(--grid-1x);
}

.itinerary-pricing-col {
    width: 180px;
    float: left;
    box-sizing: border-box;
}

.itinerary-pricing-col-desc {
    width: calc(100% - 180px);
    float: left;
    box-sizing: border-box;
}

.table-columns {
    vertical-align: top;
    display: inline-table;
    height: 100%;
    padding: var(--grid);
    box-sizing: border-box;
}

.deviation-itinerary-col {
    width: 202px;
    padding: 12px 8px;
    float: left;
    margin-right: 16px;
}

.price-cad{
width: 65px;
font-size: 14px !important;
}

.flight-col {
    display: flex !important;
    width: 1072px !important;
    padding: 2px;
}

.deviation-cal-icon {
    position: relative;
    float: right;
    right: 16px;
    bottom: 28px;
    font-size: 20px !important;
}

.date-picker {
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    font-weight: 400;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;

}
// .itinerary-heading {
.table-column .room-name.text-ellipsis {
        text-wrap:nowrap !important;
}

.date-range-picker{
    .react-datepicker-ignore-onclickoutside{
        width: 100%;
    }
    .react-datepicker-wrapper{
        .react-datepicker__input-container{
            input{
                width: 100%;
                padding: 8px;
                border: 1px solid #c4c4c4;
                border-radius: 4px;

                &:disabled {
                    background: #f2f2f2;
                }
            }
            .react-datepicker__close-icon{
                right: 16px;
            }
            .react-datepicker__close-icon::after{
                line-height: 0;
                background-color: #595959;
            }
            
        }
       
    }
    .react-datepicker__day--in-range{
        background-color: #ff6000;
    }
    .react-datepicker__day--in-range:hover,
    .react-datepicker__day--in-range:active,
    .react-datepicker__day--in-range:focus{
        background-color: #ff6000;
    }
    .react-datepicker__day--keyboard-selected{
        background-color: #ffe7d8;
    }
    .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range),
    .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range),
    .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range),
    .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range) {
    background-color: rgba(247, 133, 81, 0.662);
    }
}
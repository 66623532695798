.view-invoice-container .d-flex{
    display: flex;
    gap: 10px;
    width: 500px;
    align-items: center;
    
}

.inVoice-container{
    flex-basis: 50%;
}

.email-container{
    text-align: center;
    display: flex;
    justify-content: center
}

.email-error{
    display: none;
    justify-content: center
}

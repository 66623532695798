.information-text {
    width: calc(100% - 120px);
    float: left;
    text-align: right;
    padding: 8px 16px 0 0;
    box-sizing: border-box;
}

.information-button {
    width: 120px;
    float: left;
}

.cross-button{
    position: relative;
    left: 4px;
    float: right !important;
    bottom: 80px;
}

.border-promotion{
    border: 1px solid var(--black);
    border-radius: 6px;
}

// .promotion-icon{
//     background: red;
// }
.search-container {
    width: 100%;
    float: left;
}

.search-head {
    width: auto;
    float: left;
    padding: var(--grid-1x);
    background: var(--onyx-bg);

    -webkit-border-top-left-radius: 4px;
    -webkit-border-top-right-radius: 4px;
    -moz-border-radius-topleft: 4px;
    -moz-border-radius-topright: 4px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.search-row {
    width: 100%;
    float: left;
}

.search-contents {
    width: 100%;
    float: left;
    padding: var(--grid-1x);
    background: var(--onyx-bg);
    
    -webkit-border-radius: calc(var(--grid) / 2);
    -webkit-border-top-left-radius: 0;
    -moz-border-radius: calc(var(--grid) / 2);
    -moz-border-radius-topleft: 0;
    border-radius: calc(var(--grid) / 2);
    border-top-left-radius: 0;
}

.search-text {
    width: auto;
    float: left;
}

.grid-container {
    width: 100%;
    float: left;
    border: 1px solid var(--onyx-brdr);
    border-radius: 4px;
}

.grid-head {
    width: 100%;
    float: left;
    color: var(--white);
    background: var(--grid-head-bg);
    -webkit-border-top-left-radius: 4px;
    -webkit-border-top-right-radius: 4px;
    -moz-border-radius-topleft: 4px;
    -moz-border-radius-topright: 4px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.grid-head-group {
    width: 100%;
    float: left;
    color: var(--onyx);
    background: var(--onyx-bg);
    -webkit-border-top-left-radius: 4px;
    -webkit-border-top-right-radius: 4px;
    -moz-border-radius-topleft: 4px;
    -moz-border-radius-topright: 4px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.grid-row {
    width: 100%;
    float: left;
    border-bottom: 1px solid var(--onyx-brdr);
}

.grid-row:hover {
    background: var(--ocean-blue-opacity-10);
}

.grid-row:last-child {
    border-bottom: 0!important;
}

.quote-expired, .quote-expired:hover {
    background: var(--onyx-bg);
}

.grid-add-option {
    padding: 0!important;
    font-size: 12px!important;
    margin: 0!important;
    min-width: auto!important;
}

.quote-number {
    width: 104px;
    float: left;
    padding: var(--grid-1x);
}

.quote-group-name {
    width: 120px;
    float: left;
    padding: var(--grid-1x) var(--grid);
}

.quote-created-on {
    width: 110px;
    float: left;
    padding: var(--grid-1x) var(--grid);
}

.quote-flight-icon {
    width: var(--grid-2x);
    float: left;
    text-align: center;
}

.quote-status-confirmed {
    width: auto;
    float: left;
    padding: 0 var(--grid);
    font-size: 14px;
    border-radius: 4px;
    text-align: center;
    color: var(--white);
    background: var(--quote-status-confirmed);
}

.quote-options-container {
    width: calc(100% - 400px);
    float: left;
    padding: var(--grid-1x) var(--grid);
}

.quote-options {
    width: 112px;
    float: left;
}

.product-type {
    width: auto;
    float: left;
}

.information-icon {
    width: auto;
    float: left;
}

.quote-gateway, .quote-destination {
    width: calc(50% - 244px);
    float: left;
}

.quote-hotel-or-cruise-container {
    width: 176px;
    float: left;
}

.quote-hotel-or-cruise {
    width: calc(100% - 32px);
    float: left;
}

.quote-expiry-date {
    width: 110px;
    float: left;
}

.quote-action {
    width: 64px;
    float: left;
    padding: var(--grid-1x) 0;
    text-align: center;
}

.pagination-container {
    width: 100%;
    float: left;
    text-align: center;
    padding: var(--grid-1x) 0;
}

.page-number {
    font-size: var(--grid-1x)!important;
}

.page-number-icon {
    font-size: 14px!important;
}

.page-number:hover, .page-number-icon:hover {
    background: var(--onyx-bg)!important;
    border: 1px solid var(--onyx2)!important;
}

.quote-options-container .margin-bottom-1x:last-child {
    margin-bottom: 0!important;
}

.more-container {
    width: 24px;
    float: left;
}

.more-button {
    width: 24px;
    height: 24px;
    float: left;
    text-align: center;
    color: var(--more-button-fg);
}

.more-button:hover {
    cursor: pointer;
    text-decoration: underline;
}

.hotel-header {
    width: 100%;
    float: left;
    font-weight: 700;
    padding: 8px 16px;
    text-align: center;
    color: var(--onyx);
    border-bottom: 1px solid var(--onyx-bg)!important;
}

.hotel-pop-over-row {
    width: 100%;
    float: left;
    padding: 4px 16px;
}

.error-pop-over-row {
    width: 100%;
    float: left;
    padding: 8px 16px;
    color: var(--error-fg);
}

.popup-content {
    box-shadow: -4px 4px 16px var(--onyx-brdr)!important;
    border-radius: 4px!important;
    padding: 0!important;
    border: 0 solid var(--onyx-brdr)!important;
    width: 350px;
}

@media (min-width:481px) {
    [role=tooltip].popup-content {
    width: 400px;
    }
}
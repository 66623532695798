.hotel-pic {
    width: 240px;
    height: 160px;
    float: left;
    border-radius: 4px;
    background: var(--onyx-bg);
}

.view-quote-product-type .hotel-details {
    width: calc(100% - 240px);
    float: left;
    padding: 0 var(--grid-1x);
    box-sizing: border-box;
}

.view-quote-product-type-row h3 {
    width: auto;
    float: left;
    color: var(--itinerary-heading-fg);
    margin-top: 8px !important;
	margin-bottom: 8px !important;
}

.view-quote-product-type-row {
    width: 100%;
    float: left;
    border-radius: 4px;
    padding: var(--grid);
    box-sizing: border-box;
    background: var(--itinerary-heading-bg);
    color: var(--itinerary-heading-fg);
}

.product-row {
    padding-bottom: var(--grid-3x);
    margin-bottom: var(--grid-1x);
    border-bottom: 1px solid var(--onyx-brdr);
}

@media (min-width: 300px) and (max-width: 767px) {
    .col-option-gateway h3 {
        font-size: 17px;
        margin-bottom: 0;
        margin-top: 5px;
        font-weight: bold  !important;
    }
    .col-option-text h3 {
        margin-top: 0; 
        font-size: 17px;
        font-weight: bold  !important;
    }
}

.expiry-time {
    display: none;
}

@media (min-width: 300px) and (max-width: 1024px) {
    .expiry-time {
        text-align: right;
        color: var(--error-fg);
        background: var(--warning-bg) 0% 0% no-repeat padding-box;
        display: flex;
        width: 100%;
        justify-content: flex-end;
        align-items: center;
        padding: 10px;
    }

    .expiry-time .expiry-icon {
        color: var(--error-fg);
    }
}
.slick-disabled {
  opacity: 0;
  pointer-events: none;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus,
.slick-prev,
.slick-next,
.slick-next {
  color: inherit !important;
}

/* the slides */
.slick-slide {
  padding-right: var(--grid-1x);
}

.slick-slide .col-3 {
  width: calc(100% - 2px) !important;
}

.slick-slide:last-child {
  padding-right: 0;
}

.slick-track {
  margin-left: initial !important;
}

@media (max-width:1025px) {
  .slick-track {
    width: 100% !important;
    transform: none !important;
  }
  .slick-prev,.slick-next {
    display: none !important;
  }
}

.MuiAutocomplete-input {
  flex-grow: 0.78 !important;
}
.Mui-error {
  margin-left: 0px !important;
}

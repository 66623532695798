.date-range-picker{
    .react-daterange-picker{
        width: 100%;
        .react-daterange-picker__wrapper{
            padding: 4px;
            border-radius: 4px;
            border: thin solid #cccccc;
        }
        .MuiSvgIcon-root{
            color: rgb(137 143 145);
        }
        input{
            font-size: 15px!important;
           
        }
    }
}

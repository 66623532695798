@font-face {
  font-family: "PT Sans";
  src: url("https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap");
}

@font-face {
  font-weight: 400;
  font-style: normal;
  font-family: "muller-narrow-normal";
  src: url("../assets/fonts/MullerNarrow-Medium.otf") format("opentype");
}

@font-face {
  font-weight: 700;
  font-style: normal;
  font-family: "muller-narrow-bold";
  src: url("../assets/fonts/MullerNarrow-Bold.otf") format("opentype");
}

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100..900&family=Noto+Serif:ital,wght@1,100..900&display=swap");
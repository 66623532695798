.view-quote-container {
    width: 100%;
    float: left;
}

.quote-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-radius: 4px;
    padding: var(--grid-1x);
    font-size: var(--grid-2x);
    color: var(--white);
    background: var(--quote-header);
}

.quote-information-container {
    width: 100%;
    float: left;
}

.quote-information-row {
    width: 100%;
    float: left;
    padding: var(--grid) 0 var(--grid-2x) 0;
    box-sizing: border-box;
}

.promotion-row,
.pricing-notes-information-row,
.terms-and-conditions-information-row {
    width: 100%;
    float: left;
    box-sizing: border-box;
    padding: var(--grid-2x) var(--grid-1x);
}

.promotion-row Section,
.pricing-notes-information-row Section,
.terms-and-conditions-information-row Section {
    background: transparent !important;
    padding-top: 0!important;
}

.row-additional-information h3{
    font-weight: 700!important;
}

.terms-condition{
    height: 50%;
}

.terms-condition-container{
    width: 100%;
    padding: 12px;
    resize: none;
}

.border-pricing{
    width: 100%;
    height: auto;
    padding: 12px;
    border: 1px solid black;   
}

.cross-button-pricing{
    position: relative;
    padding: 2px;    
    float: right !important;
    bottom: 70px;
}

.icon-flipped {
    transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
}

.quote-left {
    display: flex;
    justify-content: center;
    align-items: center;
}

.quote-icon {
    margin-right: 5px;
}
@media print {

    header, .application-footer, .print-hide {
        display: none;
    }

    .hotel-pic {
        width: 200px;
    }

    .print-itinerary {
        display: block !important;
    }

    .sticky {
        position: inherit;
    }

    .pull-left {
        float: left;
    }

    .col-3 {
        width: 25%;
        float: left;
      }

 }
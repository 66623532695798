:root { 
    
    --default-grid: 8px;

    --g-4-px: calc(var(--default-grid) / 2); /* 4 */
    --grid: var(--default-grid); /* 8 */
    --grid-1x: calc(var(--grid) * 2); /* 16 */
    --grid-2x: calc(var(--grid) * 3); /* 24 */
    --grid-3x: calc(var(--grid) * 4); /* 32 */
    --grid-4x: calc(var(--grid) * 5); /* 40 */
    --grid-5x: calc(var(--grid) * 6); /* 48 */
    --grid-6x: calc(var(--grid) * 7); /* 56 */
    --grid-7x: calc(var(--grid) * 8); /* 64 */
    --grid-8x: calc(var(--grid) * 9); /* 72 */
    --grid-9x: calc(var(--grid) * 10); /* 80 */
    --grid-10x: calc(var(--grid) * 11); /* 88 */
    --grid-11x: calc(var(--grid) * 12); /* 96 */
    --grid-12x: calc(var(--grid) * 13); /* 104 */
    --grid-13x: calc(var(--grid) * 14); /* 112 */
    --grid-14x: calc(var(--grid) * 15); /* 120 */

    --weight-800: 800;
    --weight-400: 400;

    --text-align-left: left;
    --text-align-right: right;
    
}

html[data-brand=vwq] {

    --app-header-bg: var(--white);
    --app-header-link-fg: var(--black);
    --app-header-button-fg: var(--deep-aqua);
    --app-header-user-menu-fg: var(--black);
    --app-header-link-active-fg: var(--deep-aqua);
    --app-header-user-menu-text-align: var(--text-align-right);
    
    --font-icon: var(--black);
    --font-blue: var(--deep-aqua);

    --itinerary-heading-fg: var(--white);
    --itinerary-heading-bg: var(--deep-aqua);
    --quote-header: var(--deep-aqua);
    --btn-white-fg: var(--deep-aqua);
    --link-button-fg: var(--deep-aqua);
    --error-fg: var(--shiraz);
    --warning-fg: var(--squash);
    --quote-view-itinerary-row-heading-fg: var(--white);
    --quote-view-itinerary-row-heading-bg: var(--onyx2);
    --quote-view-itinerary-short-info: var(--deep-aqua);
    
    --link-button-weight: var(--weight-400);
    --quote-view-itinerary-header-weight: var(--weight-800);

    --mui-button-contained-primary-bg: var(--deep-aqua);
    --background-blue-bg: var(--tardis-blue);
    --progress-indicator-bg: var(--deep-aqua);
    --option-heading-fg: var(--deep-aqua);
    --next-link-button-fg: var(--deep-aqua);
    --mui-focused-br: var(--deep-aqua);
    --mui-focused-outline-input-notched: var(--deep-aqua);
    --mui-checked-fg: var(--deep-aqua);
    --mui-button-fg: var(--deep-aqua);
    --mui-disabled-bg: var(--gainsboro);
    --input-disabled-bg: var(--gainsboro);
    
    --mui-button-outlined-fg: var(--deep-aqua);

    --grid-head-bg: var(--deep-aqua);
    --grid-link-button-fg: var(--deep-aqua);
    --grid-quote-status-bg: var(--deep-aqua);

    --available-container-fg: var(--black);
    --itinerary-status-confirmed-bg: var(--deep-aqua);
    --itinerary-status-unconfirmed-brbr: var(--deep-aqua);

    --btn-blue-fg: var(--deep-aqua);
    --btn-green-bg: var(--deep-aqua);

    --slick-arrow-fg: var(--deep-aqua);
    --no-btn-fg: var(--deep-aqua);
    --group-contract-header-bg: var(--deep-aqua);

    --group-contract-section-header-bg: var(--tardis-blue);
    --group-contract-html-row-bg: var(--tardis-blue);
    --page-header-container-hyper-link-fg: var(--deep-aqua);
    --edit-cancel-btn-fg: var(--deep-aqua);
    --more-button-fg: var(--deep-aqua);
    --quote-status-confirmed: var(--teal-blue);
    --heart-bg: var(--deep-aqua);
    --body-fg: var(--black);
    --h1-fg: var(--black);
    --manual-quote-fg: var(--deep-aqua);
    --itinerary-promotion-row-fg: var(--deep-aqua);
    --offer-row-fg: var(--tardis-blue);
    --company-heading-bg: var(--white);
}

html[data-brand=swg] {

    --app-header-bg: var(--orange);
    --app-header-link-fg: var(--white);
    --app-header-button-fg: var(--onyx);
    --app-header-user-menu-fg: var(--onyx);
    --app-header-link-active-fg: var(--deep-aqua);
    --app-header-user-menu-text-align: var(--text-align-left);

    --font-icon: var(--orange);
    --font-blue: var(--ocean-blue);

    --itinerary-heading-fg: var(--white);
    --itinerary-heading-bg: var(--orange);
    --quote-header: var(--coral-reef);
    --btn-white-fg: var(--coral-reef);
    --link-button-fg: var(--ocean-blue);
    --error-fg: var(--guardsman-red);
    --warning-fg: var(--squash);
    --quote-view-itinerary-row-heading-fg: var(--white);
    --quote-view-itinerary-row-heading-bg: var(--onyx2);
    --quote-view-itinerary-short-info: var(--onyx);

    --link-button-weight: var(--weight-400);
    --quote-view-itinerary-header-weight: var(--weight-400);

    --mui-button-contained-primary-bg: var(--ocean-blue);
    --background-blue-bg: var(--ocean-blue);
    --progress-indicator-bg: var(--orange);
    --option-heading-fg: var(--orange);
    --next-link-button-fg: var(--ocean-blue);
    --mui-focused-br: var(--ocean-blue);
    --mui-focused-outline-input-notched: var(--ocean-blue);
    --mui-checked-fg: var(--black);
    --mui-button-fg: var(--ocean-blue);
    --mui-disabled-bg: var(--onyx-bg);
    --input-disabled-bg: var(--onyx-bg);

    --mui-button-outlined-fg: var(--ocean-blue);

    --grid-head-bg: var(--ocean-blue);
    --grid-link-button-fg: var(--ocean-blue);
    --grid-quote-status-bg: var(--sea-foam);

    --available-container-fg: var(--sea-foam);
    --itinerary-status-confirmed-bg: var(--sea-foam);
    --itinerary-status-unconfirmed-brbr: var(--ocean-blue);

    --btn-blue-fg: var(--starry-night);
    --btn-green-bg: var(--sea-foam);

    --slick-arrow-fg: var(--mariner);
    --no-btn-fg: var(--ocean-blue);
    --group-contract-header-bg: var(--teal-blue);

    --group-contract-section-header-bg: var(--orange);
    --group-contract-html-row-bg: var(--orange);
    
    --page-header-container-hyper-link-fg: var(--ocean-blue);
    --edit-cancel-btn-fg: var(--ocean-blue);
    --more-button-fg: var(--ocean-blue);
    --quote-status-confirmed: var(--sea-foam);
    --heart-bg: var(--orange);
    --body-fg: var(--onyx);
    --h1-fg: var(--onyx2);
    --manual-quote-fg: var(--sea-foam);
    --itinerary-promotion-row-fg: var(--orange);
    --offer-row-fg: var(--coral-reef);
    --company-heading-bg: var(--orange);
}

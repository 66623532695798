.titlePopup > .popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background: white;
  /* padding: 20px; */
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  color: #000;
  border: 1px solid #ccc;
}

.popup-table-row {
    width: 100%;
    /* height: 100%; */
    float: left;
    display: table-row;
    vertical-align: top;
    box-sizing: border-box;
} 

.popup-table-column {
    vertical-align: top;
    display: table-cell;
}

.popup-myLabel {
  background-color:red;
}
.table {
    display: table;
    border-collapse: collapse;
    width: 100%;
    background-color:white;
}
.colspan2 {
    grid-column: span 2;
}
.popup-border {
    border: 1px solid #000;
}
.select-column {
    width: 100%;
    display: table-cell;
    height: 85%;
    vertical-align: top;
}
.table-row-vertical-align {
  vertical-align: top;
}
.table-column-middle {
    width: 50%;
    display: table-cell;
    height: 100%;
}
.select-column {
    vertical-align: top;
    display: table-cell;
    height: 100%;
    padding: var(--grid);
    box-sizing: border-box;
}

:root {
    --onyx: #333333;
    --onyx2: #595959;
    --onyx3: #999999;
    --onyx-brdr: #CCCCCC;
    --onyx-bg: #F2F2F2;

    --white: #FFFFFF;
    --orange: #FF6000;
    
    --ocean-blue: #00589E;
    --starry-night: #2A3F64;
    --coral-reef: #008F99;
    --light-orange: #fadece;
    --warning-bg: #FFF8E6;
    --guardsman-red: #B10000;
    --sea-foam: #009476;
    --squash: #F4AE11;
    --mariner: #1976D2;
    --teal-blue: #008F99;

    --onyx-opacity-90: #33333390; /* custom added */
    --onyx-brdr-opacity-30: #cccccc30; /* custom added */
    --ocean-blue-opacity-10: #00589E10; /* custom added */
    --ocean-blue-opacity-90: #00589E90; /* custom added */
    --starry-night-opacity-00: #2A3F6400; /* custom added */

    --deep-aqua: #017F7C;
    --black: #000000;
    --squant: #666666;
    --gainsboro: #DBDCDC;
    --tardis-blue: #003C71;
    --shiraz: #B00121;
    --iridium: #3A3A3A;
    --gray97: #F7F7F7;
    --grayish-cyan: #B2B5B6;
}